.google-sign-in {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    color: white;
    font-family: Arial, sans-serif;
    border: 1px solid #4285F4;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.google-sign-in .google-logo-container {
    background-color: white;
    border-radius: 2px;
    padding: 5px;
    margin-right: 10px;
}

.google-sign-in img {
    width: 25px;
    height: 25px;
}

.google-sign-in:hover {
    background-color: #357ae8;
    border-color: #357ae8;
}
